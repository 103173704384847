import { Box, Typography, useMediaQuery } from "@mui/material";

import {
  AIHubPng,
  AILayerPng,
  CenterRingAnimatedPng,
  CenterRingAnimation,
  TaiTokensPng,
  TarsAllowPng,
  TarsLogoGrey,
  ThreeSvg,
} from "../../assets";
import Image from "../../components/common/Image";
import { isSafari } from "../../utils";

const SuperchargingSectionThree = () => {
  const isDown1450 = useMediaQuery("(max-width:1450px)");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",

        position: "relative",
        marginTop: "20px",
      }}
    >
      <Image
        src={ThreeSvg}
        alt="three svg"
        sx={{
          width: "220px",
          height: "100px",
          marginBottom: "20px",
        }}
      />
      <Typography
        fontSize={"43px"}
        fontWeight={900}
        lineHeight={"51px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textTransform={"uppercase"}
        textAlign={"center"}
        letterSpacing={"5px"}
        mb={2}
      >
        The AI network for{" "}
        <span
          style={{
            color: "rgba(169, 60, 255, 1)",
            fontFamily: "Avenir",
          }}
        >
          Solana
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",

          width: "100%",
          marginTop: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            height: "100%",

            zIndex: isDown1450 ? 2 : "auto",
            // width: "100%",
          }}
        >
          <GreyLogoWithText image={TarsAllowPng}>
            TARS allows you to train AI models within <br />
            minutes, at a fraction of the cost
          </GreyLogoWithText>
          <GreyLogoWithText image={AILayerPng}>
            $TAI IS THE AI LAYER FOR ALL SOLANA <br /> APPLICATIONS
          </GreyLogoWithText>
        </Box>
        {isSafari() ? (
          <Box>
            <Image
              src={CenterRingAnimatedPng}
              alt="tars logo animated"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: 900,
              // rotate 63deg

              position: "absolute",

              // center
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%) rotate(63deg)",
              zIndex: isDown1450 ? 1 : 0,
              opacity: isDown1450 ? 0.5 : 1,
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <source src={CenterRingAnimation} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            height: "100%",
            // width: "100%",
            zIndex: isDown1450 ? 2 : "auto",
          }}
        >
          <GreyLogoWithText borderLeft image={TaiTokensPng}>
            $TAI tokens are staked to create GPU’s, these <br />
            unlock rewards on the Solana chain
          </GreyLogoWithText>
          <GreyLogoWithText borderLeft image={AIHubPng}>
            An AI hub of unique tools for data <br /> monetization, advanced
            token utilities & <br /> mining
          </GreyLogoWithText>
        </Box>
      </Box>
    </Box>
  );
};

export default SuperchargingSectionThree;

const GreyLogoWithText = ({
  children,
  borderLeft,
  image = "",
}: {
  children: any;
  borderLeft?: boolean;
  image?: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        padding: "20px",
        borderRight: !borderLeft
          ? "1px solid rgba(156, 117, 220, .25)"
          : "none",
        borderLeft: borderLeft ? "1px solid rgba(156, 117, 220, .25)" : "none",
        marginBottom: "100px",
        paddingBottom: "50px",
      }}
    >
      <Image
        src={image ? image : TarsLogoGrey}
        alt="tars logo"
        sx={{
          width: "76px",
          height: "76px",
          marginBottom: "10px",
        }}
      />
      <Typography
        fontSize={"20px"}
        fontWeight={500}
        lineHeight={"24px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        {children}
      </Typography>
    </Box>
  );
};
