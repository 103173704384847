import { FC } from "react";
import AppRoutes from ".";
import { Box, useMediaQuery } from "@mui/material";
import Lottie from "react-lottie";

const AppLayout: FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          maxWidth: "1700px",
          margin: "0 auto",
        }}
      >
        <AppRoutes />
      </div>
    </Box>
  );
};
export default AppLayout;
