import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Image from "../../components/common/Image";
import {
  DiscordSvg,
  TarsAIText,
  TarsLogoFooter,
  TelegramSvg,
  TwitterSvg,
} from "../../assets";

const Footer = () => {
  const isDown768 = useMediaQuery("(max-width:768px)");
  return (
    <Box
      sx={{
        backgroundColor: "rgba(10, 5, 18, 1)",
        width: "100%",

        padding: isDown768 ? "16px" : "50px",
        paddingBottom: "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          flexDirection: isDown768 ? "column" : "row",
        }}
      >
        <Box
          sx={{
            width: isDown768 ? "100%" : 500,
            marginBottom: isDown768 ? "48px" : "0",
          }}
        >
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: isDown768 ? "center" : "flex-start",
              mb: isDown768 ? 6 : 2,
              flexDirection: isDown768 ? "column" : "row",
            }}
          >
            <Image
              src={TarsLogoFooter}
              alt="tars logo footer"
              sx={{
                width: "104px",
                height: "104px",
                marginBottom: isDown768 ? "10px" : "0",
              }}
            />
            <Image
              src={TarsAIText}
              alt="tars ai text"
              sx={{
                width: "100px",
              }}
            />
          </Box>
          <Typography
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={isDown768 ? "24px" : "30px"}
            fontFamily="Avenir"
            textAlign={isDown768 ? "center" : "justify"}
            ml={isDown768 ? 0 : 2}
            sx={{
              opacity: "70%",
            }}
          >
            TARS aims to bridge the gap between AI and Web3 worlds by providing
            a unified platform for AI-powered tools and services, enabling
            organizations and individuals to effortlessly transition from Web2
            to Web3.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: isDown768 ? 0 : "30px",
            gap: "30px",
          }}
        >
          <Box>
            <Typography
              fontSize={isDown768 ? "16px" : "20px"}
              fontWeight={900}
              lineHeight={isDown768 ? "19px" : "23px"}
              fontFamily="Avenir"
              textAlign={"center"}
              mb={3}
            >
              About
            </Typography>
            <Box>
              <Typography
                fontSize={isDown768 ? "16px" : "18px"}
                fontWeight={400}
                lineHeight={isDown768 ? "22px" : "25px"}
                fontFamily="Avenir"
                textAlign={"center"}
                color="rgba(156, 117, 220, 1)"
                onClick={() => window.open("https://docs.tars.pro", "_blank")}
                sx={{
                  cursor: "pointer",
                }}
              >
                Docs
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              fontSize={isDown768 ? "16px" : "20px"}
              fontWeight={900}
              lineHeight={isDown768 ? "19px" : "23px"}
              fontFamily="Avenir"
              textAlign={"center"}
              mb={3}
            >
              Explore
            </Typography>
            <Box>
              <Tooltip
                title={"Coming Soon"}
                placement="top"
                enterTouchDelay={0}
              >
                <Typography
                  fontSize={isDown768 ? "16px" : "18px"}
                  fontWeight={400}
                  lineHeight={isDown768 ? "22px" : "25px"}
                  fontFamily="Avenir"
                  textAlign={"center"}
                  color="rgba(156, 117, 220, 1)"
                  onClick={() => {
                    window.open("https://ai.tars.pro/", "_blank");
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  TARS AI Hub
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Typography
              fontSize={isDown768 ? "16px" : "20px"}
              fontWeight={900}
              lineHeight={isDown768 ? "19px" : "23px"}
              fontFamily="Avenir"
              textAlign={"center"}
              mb={3}
            >
              Contact
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LinkWithIcon
                Icon={TelegramSvg}
                link="https://t.me/tars_pro"
                isMobile={isDown768}
              />

              <LinkWithIcon
                Icon={DiscordSvg}
                link="https://discord.com/invite/tarsai"
                isMobile={isDown768}
              />
              <LinkWithIcon
                Icon={TwitterSvg}
                link="https://x.com/tarsprotocol?mx=2"
                isMobile={isDown768}
              />
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Box>
              <Typography
                fontSize={"18px"}
                fontWeight={400}
                lineHeight={"25px"}
                fontFamily="Avenir"
                textAlign={"center"}
                color="rgba(156, 117, 220, 1)"
                onClick={() => {
                  window.open("https://tars.ai", "_blank");
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                Docs
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={"18px"}
                fontWeight={400}
                lineHeight={"25px"}
                fontFamily="Avenir"
                textAlign={"center"}
                color="rgba(156, 117, 220, 1)"
                onClick={() => {
                  window.open("https://tars.ai", "_blank");
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                TARS AI Hub
              </Typography>
            </Box>
          </Box> */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              marginTop: "10px",
            }}
          >
            <LinkWithIcon Icon={TelegramSvg} text="Telegram" />

            <LinkWithIcon Icon={DiscordSvg} text="Discord" />
            <LinkWithIcon Icon={TwitterSvg} text="Twitter" />
          </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          padding: isDown768 ? "40px 0px 0px 0px" : "20px 0px",
        }}
      >
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          lineHeight={"16px"}
          fontFamily="Avenir"
          sx={{
            opacity: "50%",
          }}
          ml={isDown768 ? 0 : 2}
          textAlign={isDown768 ? "center" : "left"}
        >
          © 2024 TARS Protocol. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

const LinkWithIcon = ({
  Icon,
  link,
  isMobile,
}: {
  Icon: any;
  link: string;
  isMobile: boolean;
}) => {
  return (
    <IconButton
      onClick={() => {
        window.open(link, "_blank");
      }}
      sx={{
        borderRadius: "100%",
        minWidth: "auto !important",
      }}
    >
      <Image
        src={Icon}
        alt=""
        sx={{
          width: isMobile ? "16px" : "24px",
          height: isMobile ? "16px" : "24px",
        }}
      />
    </IconButton>
  );
};
