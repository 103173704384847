// takes and render image in <img /> tag

// define type of Image props
type ImageProps = {
  src: string;
  alt: string;
  sx?: React.CSSProperties;
};

const Image = ({ src, alt, sx }: ImageProps) => {
  return <img src={src} alt={alt} style={sx} />;
};

export default Image;
