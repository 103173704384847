import { Box, useMediaQuery } from "@mui/material";

import Image from "../../components/common/Image";
import {
  LeftSidePattern,
  RightRingAnimatedPng,
  RightRingAnimation,
  RightSidePattern,
} from "../../assets";

import SuperchargingSectionOne from "./SuperchargingSectionOne";
import SuperchargingSectionThree from "./SuperchargingSectionThree";
import SuperchargingSectionTwo from "./SuperchargingSectionTwo";
import { isSafari } from "../../utils";

const Supercharging = () => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #10081D 0%, #000000 100%)",

        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Image
        src={LeftSidePattern}
        alt="left side pattern"
        sx={{
          height: "900px",
          position: "absolute",
          left: 0,
          zIndex: 1,
        }}
      />
      <Image
        src={RightSidePattern}
        alt="right side pattern"
        sx={{
          height: "900px",
          position: "absolute",
          right: 0,
          zIndex: 1,
        }}
      />
      {isSafari() ? (
        <Box
          sx={{
            position: "absolute",
            zIndex: 200,
            right: isDown1200 ? "-15%" : "-20%",
            top: isDown1000 ? "12%" : isDown1200 ? "10%" : "5%",
            height: isDown1000 ? "15%" : isDown1200 ? "20%" : "30%",
            transform: "rotate(135deg)",
            width: isDown1000 ? "25%" : isDown1200 ? "30%" : "40%",
          }}
        >
          <Image
            src={RightRingAnimatedPng}
            alt="tars logo animated"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            right: "-35%",
            height: isDown1200 ? "40%" : "35%",
            transform: "rotate(135deg)",
            width: isDown1200 ? "75%" : "70%",
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <source src={RightRingAnimation} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
      <SuperchargingSectionOne />
      <SuperchargingSectionTwo />
      <SuperchargingSectionThree />
    </Box>
  );
};

export default Supercharging;
