import { Box } from "@mui/material";
import React from "react";
import SuperchargingSectionOneMobile from "./SuperchargingSectionOneMobile";
import SuperchargingSectionTwoMobile from "./SuperchargingSectionTwoMobile";
import SuperchargingSectionThreeMobile from "./SuperchargingSectionThreeMobile";

const SuperchargingMobile = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #10081D 0%, #000000 100%)",

        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SuperchargingSectionOneMobile />
      <SuperchargingSectionTwoMobile />
      <SuperchargingSectionThreeMobile />
    </Box>
  );
};

export default SuperchargingMobile;
