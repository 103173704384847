import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Image from "../../components/common/Image";
import {
  BackedBySolana,
  HeroBackgroundMobile,
  TarsLogoAnimated,
  TarsLogoAnimatedPng,
} from "../../assets";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const HeroSectionMobile = () => {
  const isDown500 = useMediaQuery("(max-width:500px)");
  const isDown400 = useMediaQuery("(max-width:400px)");
  const isDown450 = useMediaQuery("(max-width:450px)");
  const isDown600 = useMediaQuery("(max-width:600px)");

  const isiPhone = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        borderBottom: "2px solid rgba(134, 60, 255, 1)",
      }}
    >
      <Image
        src={HeroBackgroundMobile}
        alt="hero background"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Typography
            fontSize={"40px"}
            fontWeight={900}
            lineHeight={"38px"}
            fontFamily="Avenir"
            textTransform={"uppercase"}
            color="rgba(255, 255, 255, 1)"
            mb={2}
            textAlign={"center"}
          >
            Solana’s AI
            <br /> Infrastructure
          </Typography>
          <Typography
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"24px"}
            fontFamily="Avenir"
            textTransform={"none"}
            color="rgba(169, 60, 255, 1)"
            mb={2}
            textAlign={"center"}
          >
            Introducing TARS, powered by GPT-4
            <span>o</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: isDown400
                ? "90%"
                : isDown500
                ? "350px"
                : "calc(100% - 25%)",
              border: "2px solid rgba(169, 60, 255, 0.19)",
              height: "50px",
              padding: "10px 20px",
              borderRadius: "30px",
              background: "#12071B",
              marginBottom: "-20px",
            }}
          >
            <Typography
              fontSize={"18px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Avenir"
              color="rgba(255, 255, 255, 1)"
            >
              Backed By
            </Typography>
            <Image
              src={BackedBySolana}
              alt="backed by solana"
              sx={{
                height: "24px",
              }}
            />
          </Box>
          {isiPhone ? (
            <Box
              sx={{
                width: isDown400
                  ? 250
                  : isDown450
                  ? 300
                  : isDown600
                  ? 400
                  : 600,
                mb: isDown450 ? -5 : isDown600 ? -5 : -2,
              }}
            >
              <Image
                src={TarsLogoAnimatedPng}
                alt="tars logo animated"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : (
            <Box
              style={{
                width: isDown450 ? "100%" : isDown500 ? "120%" : "100vw",
              }}
            >
              <video
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <source src={TarsLogoAnimated} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LearnMoreButton />
            {/* <JoinNowButton /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSectionMobile;

const LearnMoreButton = () => {
  return (
    <Button
      sx={{
        backgroundColor: "rgba(169, 60, 255, 1)",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "30px",

        textTransform: "uppercase",
        height: "40px",

        width: "140px",

        "&:hover": {
          backgroundColor: "rgba(169, 60, 255, 1)",
        },

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => window.open("https://ai.tars.pro/", "_blank")}
    >
      <Typography
        fontSize={"14px"}
        fontWeight={500}
        lineHeight={"16px"}
        fontFamily="Avenir"
        color="#fff"
        marginTop={"4px"}
      >
        Try App
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          height: "18px",
          width: "18px",
          marginLeft: "8px",
        }}
      />
    </Button>
  );
};
// join now button will be transparent with no border
const JoinNowButton = () => {
  return (
    <Button
      sx={{
        backgroundColor: "transparent",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "30px",
        height: "40px",

        width: "140px",

        textTransform: "uppercase",

        "&:hover": {
          backgroundColor: "transparent",
        },

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => window.open("https://t.me/tars_pro", "_blank")}
    >
      <Typography
        fontSize={"14px"}
        fontWeight={500}
        lineHeight={"16px"}
        fontFamily="Avenir"
        color="rgba(169, 60, 255, 1)"
        marginTop={"4px"}
      >
        Join Now
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          height: "18px",
          width: "18px",
          marginLeft: "8px",
          color: "rgba(169, 60, 255, 1)",
        }}
      />
    </Button>
  );
};
