import React from "react";
import {
  ChatbotScreenshots,
  ChatbotScreenshotsPng,
  OneSvg,
} from "../../assets";
import Image from "../../components/common/Image";
import { Box, Typography, useMediaQuery } from "@mui/material";

const SuperchargingSectionOne = () => {
  const isDown1100 = useMediaQuery("(max-width:1100px)");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px 100px",
        zIndex: 2,
        position: "relative",
      }}
    >
      <Image
        src={OneSvg}
        alt="one svg"
        sx={{
          width: "220px",
          height: "100px",
          marginBottom: "20px",
        }}
      />
      <Typography
        fontSize={"43px"}
        fontWeight={900}
        lineHeight={"51px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textTransform={"uppercase"}
        mb={2}
      >
        Supercharging{" "}
        <span
          style={{
            color: "rgba(169, 60, 255, 1)",
            fontFamily: "Avenir",
          }}
        >
          GPT-4
          <span
            style={{
              fontSize: "30px",
              lineHeight: "13px",
            }}
          >
            o
          </span>
        </span>
      </Typography>
      <Typography
        fontSize={"20px"}
        fontWeight={400}
        lineHeight={"30px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textAlign={"center"}
        sx={{
          opacity: 0.7,
        }}
        mb={4}
      >
        TARS is an AI infrastructure capable of training AI models, monetizing
        data, and providing access to <br /> powerful GPU’s. It’s an advanced
        ecosystem of modular AI applications backed by the Solana foundation.
      </Typography>

      <Image
        src={ChatbotScreenshotsPng}
        alt="chatbot screenshots"
        sx={{
          width: isDown1100 ? "100%" : "auto",
          // height: "500px",
        }}
      />
    </Box>
  );
};

export default SuperchargingSectionOne;
