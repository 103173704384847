import React from "react";
import {
  AIHubPng,
  AILayerPng,
  TaiTokensPng,
  TarsAllowPng,
  TarsLogoGrey,
  ThreeSvg,
} from "../../assets";
import { Box, Typography } from "@mui/material";
import Image from "../../components/common/Image";

const SuperchargingSectionThreeMobile = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px 20px",
        zIndex: 2,
        position: "relative",
      }}
    >
      <Image
        src={ThreeSvg}
        alt="three svg"
        sx={{
          width: "220px",
          height: "100px",
          marginBottom: "20px",
        }}
      />
      <Typography
        fontSize={"32px"}
        fontWeight={900}
        lineHeight={"38px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textTransform={"uppercase"}
        mb={2}
        textAlign={"center"}
        mt={-7}
      >
        The AI network for <br />
        <span
          style={{
            color: "rgba(169, 60, 255, 1)",
            fontFamily: "Avenir",
          }}
        >
          Solana
        </span>
      </Typography>
      <GreyLogoWithText image={TarsAllowPng}>
        TARS allows you to train AI models within minutes, at a fraction of the
        cost
      </GreyLogoWithText>

      <GreyLogoWithText image={TaiTokensPng}>
        $TAI tokens are staked to create GPU’s, these unlock rewards on the
        Solana chain
      </GreyLogoWithText>
      <GreyLogoWithText image={AILayerPng}>
        $TAI IS THE AI LAYER FOR ALL SOLANA APPLICATIONS
      </GreyLogoWithText>

      <GreyLogoWithText last image={AIHubPng}>
        An AI hub of unique tools for data monetization, advanced token
        utilities & mining
      </GreyLogoWithText>
    </Box>
  );
};

export default SuperchargingSectionThreeMobile;

const GreyLogoWithText = ({
  children,
  last,
  image,
}: {
  children: any;
  last?: boolean;
  image?: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        padding: "40px 20px",

        marginBottom: "40px",
        borderBottom: last ? "none" : "1px solid rgba(156, 117, 220, .25)",
      }}
    >
      <Image
        src={image ? image : TarsLogoGrey}
        alt="tars logo"
        sx={{
          width: "76px",
          height: "76px",
          marginBottom: "10px",
        }}
      />
      <Typography
        fontSize={"20px"}
        fontWeight={500}
        lineHeight={"24px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        {children}
      </Typography>
    </Box>
  );
};
