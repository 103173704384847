import { Box, useMediaQuery } from "@mui/material";
import HeroSection from "./HeroSection";
import HeroSectionMobile from "./HeroSectionMobile";
import Supercharging from "./Supercharging";
import SuperchargingMobile from "./SuperchargingMobile";
import BackedBy from "./BackedBy";
import JoinNow from "./JoinNow";
import Footer from "./Footer";

const Home = () => {
  const isDown768 = useMediaQuery("(max-width:768px)");

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "hidden",
      }}
    >
      {isDown768 ? <HeroSectionMobile /> : <HeroSection />}
      {isDown768 ? <SuperchargingMobile /> : <Supercharging />}
      <BackedBy />
      <JoinNow />
      <Footer />
    </Box>
  );
};

export default Home;
