import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import {
  BackedBySolana,
  HeroBackground,
  TarsLogoAnimated,
  TarsLogoAnimatedPng,
} from "../../assets";
import Image from "../../components/common/Image";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isSafari } from "../../utils";

const HeroSection = () => {
  const isDown1400 = useMediaQuery("(max-width:1400px)");
  const isDown1100 = useMediaQuery("(max-width:1100px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  const isDown768 = useMediaQuery("(max-width:768px)");
  const isDown900 = useMediaQuery("(max-width:900px)");

  const isiPhone = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box
      sx={{
        position: "relative",
        //backgroundColor: "rgba(38, 24, 48, 1)",
        borderTop: "2px solid rgba(38, 24, 48, 1)",
        borderBottom: "2px solid rgba(134, 60, 255, 1)",
      }}
    >
      <Image
        src={HeroBackground}
        alt="hero background"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      {isSafari() ? (
        <Box
          sx={{
            position: "absolute",
            top: isDown768 ? "auto" : "50%",
            right: isDown768 ? "auto" : "0%",
            transform: isDown768 ? "auto" : "translateY(-50%)",
            width: isDown900 ? 450 : isDown1100 ? 500 : 600,
          }}
        >
          <Image
            src={TarsLogoAnimatedPng}
            alt="tars logo animated"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: isDown768 ? "auto" : "50%",
            right: isDown768 ? "auto" : "-5%",
            transform: isDown768 ? "auto" : "translateY(-50%)",
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              height: isDown1100 ? "600px" : isDown1400 ? "700px" : "900px",
              width: isDown1100 ? "600px" : isDown1400 ? "700px" : "900px",
              maxWidth: isDown1100 ? "600px" : isDown1400 ? "700px" : "900px",
            }}
          >
            <source src={TarsLogoAnimated} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          top: isDown1400 ? "50%" : "45%",
          left: "10%",
          transform: "translateY(-50%)",
        }}
      >
        <Box mb={isDown1000 ? 4 : isDown1400 ? 6 : 10}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "350px",
              border: "2px solid rgba(169, 60, 255, 0.19)",
              height: "50px",
              padding: "10px 20px",
              borderRadius: "30px",
              background: "#12071B",
              marginBottom: isDown1400 ? "40px" : "60px",
            }}
          >
            <Typography
              fontSize={"18px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Avenir"
              color="rgba(255, 255, 255, 1)"
            >
              Backed By
            </Typography>
            <Image
              src={BackedBySolana}
              alt="backed by solana"
              sx={{
                height: "24px",
              }}
            />
          </Box>
          <Typography
            fontSize={isDown1000 ? "32px" : isDown1400 ? "48px" : "64px"}
            fontWeight={900}
            lineHeight={isDown1000 ? "40px" : isDown1400 ? "60px" : "80px"}
            fontFamily="Avenir"
            textTransform={"uppercase"}
            color="rgba(255, 255, 255, 1)"
            mb={2}
          >
            Solana’s AI <br />
            Infrastructure
          </Typography>
          <Typography
            fontSize={isDown1000 ? "20px" : isDown1400 ? "30px" : "40px"}
            fontWeight={500}
            lineHeight={isDown1000 ? "24px" : isDown1400 ? "36px" : "60px"}
            fontFamily="Avenir"
            color="rgba(169, 60, 255, 1)"
            textTransform={"uppercase"}
          >
            Introducing Tars, <br />
            powered by GPT-4
            <span
              style={{
                fontSize: "30px",
                lineHeight: "45px",
              }}
            >
              o
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <LearnMoreButton />
          {/* <JoinNowButton /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;

const LearnMoreButton = () => {
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  return (
    <Button
      sx={{
        backgroundColor: "rgba(169, 60, 255, 1)",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "30px",

        textTransform: "uppercase",
        height: isDown1000 ? "48px" : "58px",

        width: isDown1000 ? "150px" : "200px",

        "&:hover": {
          backgroundColor: "rgba(169, 60, 255, 1)",
        },

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => window.open("https://ai.tars.pro/", "_blank")}
    >
      <Typography
        fontSize={isDown1000 ? "16px" : "20px"}
        fontWeight={500}
        lineHeight={"24px"}
        fontFamily="Avenir"
        color="#fff"
        marginTop={"4px"}
      >
        Try App
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          height: "18px",
          width: "18px",
          marginLeft: "8px",
        }}
      />
    </Button>
  );
};
// join now button will be transparent with no border
const JoinNowButton = () => {
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  return (
    <Button
      sx={{
        backgroundColor: "transparent",
        color: "rgba(255, 255, 255, 1)",
        borderRadius: "30px",
        width: isDown1000 ? "150px" : "200px",
        height: isDown1000 ? "48px" : "58px",
        textTransform: "uppercase",

        "&:hover": {
          backgroundColor: "transparent",
        },

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => window.open("https://t.me/tars_pro", "_blank")}
    >
      <Typography
        fontSize={isDown1000 ? "16px" : "20px"}
        fontWeight={500}
        lineHeight={"24px"}
        fontFamily="Avenir"
        color="rgba(169, 60, 255, 1)"
        marginTop={"4px"}
      >
        Join Now
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          height: "18px",
          width: "18px",
          marginLeft: "8px",
          color: "rgba(169, 60, 255, 1)",
        }}
      />
    </Button>
  );
};
