import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Image from "../../components/common/Image";
import {
  DiscordSvg,
  JoinNowPattern,
  JoinNowPatternMobile,
  TelegramSvg,
  TwitterSvg,
} from "../../assets";

const JoinNow = () => {
  const isDown768 = useMediaQuery("(max-width:768px)");
  const isDown1150 = useMediaQuery("(max-width:1150px)");

  if (isDown768) {
    return (
      <Box
        sx={{
          position: "relative",
          background: "linear-gradient(180deg, #000000 0%, #150925 100%)",
          marginTop: "100px",
        }}
      >
        <Image
          src={JoinNowPatternMobile}
          alt="join now pattern"
          sx={{
            width: "100%",
            height: "auto",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",

            width: "100%",
          }}
        >
          <Typography
            fontSize={"24px"}
            fontWeight={500}
            lineHeight={"28px"}
            fontFamily="Avenir"
            color="rgba(255, 255, 255, 1)"
            textTransform={"uppercase"}
            textAlign={"center"}
            mb={2}
          >
            JOIN THE TARS TRIBE
          </Typography>
          <Typography
            fontSize={"16px"}
            fontWeight={500}
            lineHeight={"24px"}
            fontFamily="Avenir"
            textAlign={"center"}
            color="rgba(156, 117, 220, 1)"
          >
            Stay up to date with the latest news
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              maxWidth: "400px",
              marginTop: "50px",
            }}
          >
            <RenderCircleWithIcon
              Icon={TelegramSvg}
              link="https://t.me/tars_pro"
              isMobile
            />
            <RenderCircleWithIcon
              Icon={DiscordSvg}
              link="https://discord.com/invite/tarsai"
              isMobile
            />
            <RenderCircleWithIcon
              Icon={TwitterSvg}
              link="https://x.com/tarsprotocol?mx=2"
              isMobile
            />
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        position: "relative",
        background: "linear-gradient(180deg, #000000 0%, #150925 100%)",
      }}
    >
      <Image
        src={JoinNowPattern}
        alt="join now pattern"
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",

          width: "100%",
        }}
      >
        <Typography
          fontSize={isDown1150 ? "30px" : "34px"}
          fontWeight={500}
          lineHeight={isDown1150 ? "34px" : "41px"}
          fontFamily="Avenir"
          color="rgba(255, 255, 255, 1)"
          textTransform={"uppercase"}
          textAlign={"center"}
          mb={2}
        >
          JOIN THE TARS TRIBE
        </Typography>
        <Typography
          fontSize={isDown1150 ? "20px" : "22px"}
          fontWeight={500}
          lineHeight={isDown1150 ? "22px" : "24px"}
          fontFamily="Avenir"
          textAlign={"center"}
          color="rgba(156, 117, 220, 1)"
        >
          Stay up to date with the latest news
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "420px",
            marginTop: isDown1150 ? "30px" : "50px",
          }}
        >
          <RenderCircleWithIcon
            Icon={TelegramSvg}
            link="https://t.me/tars_pro"
          />
          <RenderCircleWithIcon
            Icon={DiscordSvg}
            link="https://discord.com/invite/tarsai"
          />
          <RenderCircleWithIcon
            Icon={TwitterSvg}
            link="https://x.com/tarsprotocol?mx=2"
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderCircleWithIcon = ({
  Icon,
  isMobile,
  link,
}: {
  Icon: any;
  isMobile?: boolean;
  link?: string;
}) => {
  const isDown1150 = useMediaQuery("(max-width:1150px)");
  return (
    <Box
      sx={{
        height: isMobile ? "54px" : isDown1150 ? "64px" : "70px",
        width: isMobile ? "54px" : isDown1150 ? "64px" : "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "40px",
        background: "linear-gradient(180deg, #2B0957 0%, #541F7D 100%)",
        cursor: "pointer",

        "&:hover": {
          background: "linear-gradient(180deg, #541F7D 0%, #2B0957 100%)",
        },
      }}
      onClick={() => window.open(link, "_blank")}
    >
      <Image
        src={Icon}
        alt=""
        sx={{
          width: isMobile ? "24px" : "30px",
        }}
      />
    </Box>
  );
};

export default JoinNow;
