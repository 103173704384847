import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  BackedByPattern,
  BackedByPatternMobile,
  BackedBySolana,
  CenterRingAnimation,
  CenterRingAnimatedPng,
} from "../../assets";
import Image from "../../components/common/Image";

const BackedBy = () => {
  const isDown768 = useMediaQuery("(max-width:768px)");
  const isDown1000 = useMediaQuery("(max-width:1000px)");
  const isDown450 = useMediaQuery("(max-width:450px)");
  const isDown350 = useMediaQuery("(max-width:350px)");

  const isiPhone = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (isDown768) {
    return (
      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
        }}
      >
        {isiPhone ? (
          <Box
            sx={{
              width: isDown350 ? 300 : isDown450 ? 380 : 500,
              // rotate 63deg

              position: "absolute",

              // center
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) ",
              zIndex: -1,
            }}
          >
            <Image
              src={CenterRingAnimatedPng}
              alt="tars logo animated"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: 700,
              // rotate 63deg

              position: "absolute",

              // center
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) ",
              zIndex: -1,
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <source src={CenterRingAnimation} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Box>
        )}
        <Image
          src={BackedByPatternMobile}
          alt="backed by pattern mobile"
          sx={{
            width: "100%",
            height: "auto",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",

            width: "100%",
          }}
        >
          <Typography
            fontSize={"21px"}
            fontWeight={900}
            lineHeight={"24px"}
            fontFamily="Avenir"
            color="rgba(255, 255, 255, 1)"
            textTransform={"uppercase"}
            textAlign={"center"}
            mb={2}
          >
            Backed by
          </Typography>
          <Image
            src={BackedBySolana}
            alt="backed by solana"
            sx={{
              width: "230px",
              height: "38px",
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        position: "relative",
        mb: 7,
      }}
    >
      <Image
        src={BackedByPattern}
        alt="backed by pattern"
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          width: "100%",
        }}
      >
        <Typography
          fontSize={isDown1000 ? "36px" : "48px"}
          fontWeight={900}
          lineHeight={isDown1000 ? "48px" : "57px"}
          fontFamily="Avenir"
          color="rgba(255, 255, 255, 1)"
          textTransform={"uppercase"}
          textAlign={"center"}
          mr={4}
        >
          Backed by
        </Typography>
        <Image
          src={BackedBySolana}
          alt="backed by solana"
          sx={{
            width: isDown1000 ? "400px" : "550px",
            height: "90px",
          }}
        />
      </Box>
    </Box>
  );
};

export default BackedBy;
