import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Theme from './theme'
import AppLayout from './router/Layout'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Theme>
      <AppLayout />
    </Theme>
  </React.StrictMode>
)
