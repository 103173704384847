import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Button,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Menu, TarsLogoTextWhite } from "../assets";

const drawerWidth = "100%";

const navItems = [
  {
    name: "Home",
    activeFontColor: "rgba(134, 60, 255, 1)",
    fontWeight: 500,
    fontSize: "18px",
    activeFontFamily: "FuturaBold",
    fontFamily: "FuturaMedium",
    id: "home",
  },
  {
    name: "Docs",
    activeFontColor: "rgba(134, 60, 255, 1)",
    fontWeight: 500,
    fontSize: "18px",
    activeFontFamily: "FuturaBold",
    fontFamily: "FuturaMedium",
    id: "gpu",
    onClick: () => window.open("https://docs.tars.pro", "_blank"),
  },

  {
    name: "AI Hub",
    activeFontColor: "rgba(134, 60, 255, 1)",
    fontWeight: 500,
    fontSize: "18px",
    activeFontFamily: "FuturaBold",
    fontFamily: "FuturaMedium",
    id: "tokenomics",
    showComingSoon: true,
  },
  {
    name: "Stake & GPU",
    activeFontColor: "rgba(134, 60, 255, 1)",
    fontWeight: 500,
    fontSize: "18px",
    activeFontFamily: "FuturaBold",
    fontFamily: "FuturaMedium",
    id: "usecases",
    showComingSoon: false,
    onClick: () => window.open('https://stake.tars.pro/', '_blank')
  },
];

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("Home");
  const isDown1000 = useMediaQuery("(max-width:1000px)");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavItemClick = (itemName: string, itemId: string) => {
    setActiveItem(itemName);

    // const element = document.getElementById(itemId);
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth" });
    // }

    if (isDown1000) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = navItems.map((item) => document.getElementById(item.id));
      let currentSection = "Home";

      for (const section of sections) {
        if (section) {
          const offsetTop = section.offsetTop;
          const offsetHeight = section.offsetHeight;

          if (
            scrollPosition >= (offsetTop ?? 0) - 50 &&
            scrollPosition < (offsetTop ?? 0) + (offsetHeight ?? 0) - 50
          ) {
            currentSection = section.id ?? "Home";
            break;
          }
        }
      }

      setActiveItem(
        navItems.find((item) => item.id === currentSection)?.name ?? "Home"
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        marginTop: "16px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "16px",
          top: "16px",
        }}
      >
        <img
          src={TarsLogoTextWhite}
          alt="Logo"
          style={{
            height: isDown1000 ? "50px" : "85px",
            maxWidth: isDown1000 ? "135px" : "150px",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: "16px",
          top: "16px",
        }}
      >
        <IconButton onClick={handleDrawerToggle}>
          <Close sx={{ color: "#ffffff" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#282828",
          position: "absolute",
          top: "76px",
        }}
      />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "left",
                paddingLeft: "10%",
                paddingY: "30px",
                gap: "4px",
                justifyContent: isDown1000 ? "center" : "flex-start",
                borderBottom: "1px solid #282828",
                marginX: "16px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                item.name === "Home" && handleNavItemClick(item.name, item.id);

                if (item.onClick) {
                  item.onClick();
                  setMobileOpen(false);
                }

                //handleNavItemClick(item.name, item.id)
              }}
            >
              <Tooltip
                title={item.showComingSoon ? "Coming Soon" : ""}
                placement="top"
                enterTouchDelay={0}
              >
                <Typography
                  fontSize={"18px"}
                  fontWeight={500}
                  lineHeight={"21px"}
                  fontFamily="Avenir"
                  color={
                    activeItem === item.name
                      ? "rgba(134, 60, 255, 1)"
                      : "#FFFFFF"
                  }
                >
                  {item.name}
                </Typography>
              </Tooltip>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",

        width: "calc(100% - 8px)",
        paddingRight: isDown1000 ? "20px" : "2%",
        paddingLeft: isDown1000 ? "10px" : "2%",
        height: "85px",
        alignItems: "center",
        maxWidth: isDown1000 ? "100%" : "90%",

        //marginTop: isDown1000 ? "0px" : "3px",
      }}
    >
      <AppBar
        component="nav"
        sx={{
          background: "transparent",
          boxShadow: "none",
          width: "100%",
        }}
        position="static"
      >
        <Toolbar sx={{ px: "0px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { md: "block" },
              WebkitWritingMode: "vertical-lr",
            }}
          >
            <img
              src={TarsLogoTextWhite}
              alt="Logo"
              style={{
                // width: "100%",

                height: isDown1000 ? "50px" : "85px",
                maxWidth: isDown1000 ? "135px" : "150px",
                // minWidth: isDown1000 ? "60px" : "55px",
                cursor: "pointer",
                display: mobileOpen ? "none" : "flex",
              }}
              onClick={() => window.location.replace("/")}
            />
          </Box>
          <Box
            sx={{
              display: isDown1000 ? "none" : "flex",
              gap: "40px",
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item.name}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                }}
                sx={{
                  textTransform: "none",
                  padding: 0,
                  // fontSize: item.fontSize,
                  // fontFamily:
                  //   activeItem === item.name
                  //     ? item.activeFontFamily
                  //     : item.fontFamily,
                  // fontWeight: item.fontWeight,
                  // color:
                  //   activeItem === item.name ? item.activeFontColor : "#FFFFFF",
                  // lineHeight: "21px",
                  // letterSpacing: "0.02em",
                  cursor: "pointer",
                  minWidth: "50px",
                }}
                disableRipple
              >
                <Tooltip
                  title={item.showComingSoon ? "Coming Soon" : ""}
                  placement="top"
                  enterTouchDelay={0}
                >
                  <Typography
                    fontSize={"18px"}
                    fontWeight={500}
                    lineHeight={"21px"}
                    fontFamily="Avenir"
                    color={
                      activeItem === item.name
                        ? "rgba(134, 60, 255, 1)"
                        : "#FFFFFF"
                    }
                  >
                    {item.name}
                  </Typography>
                </Tooltip>
              </Button>
            ))}
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              display: isDown1000 ? "block" : "none",
              visibility: mobileOpen ? "hidden" : "visible",
            }}
          >
            <img
              src={Menu}
              alt="Menu"
              style={{ width: "24px", height: "24px" }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true,
          }}
          disableRestoreFocus
          sx={{
            display: { sm: "block", md: "block", lg: "none" },
            zIndex: 2,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              zIndex: 2,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              backgroundImage: "none",
              backdropFilter: "blur(4px)",
              justifyContent: "center",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
