import React from "react";
import Image from "../../components/common/Image";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  ChatbotScreenshots,
  OneSvg,
  RightRingAnimation,
  RightRingAnimatedPng,
  ChatbotScreenshotsMobilePng,
} from "../../assets";

const SuperchargingSectionOneMobile = () => {
  const isDown600 = useMediaQuery("(max-width:600px)");

  const isiPhone = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "100px 20px",
        zIndex: 2,
        position: "relative",
      }}
    >
      <Image
        src={OneSvg}
        alt="one svg"
        sx={{
          width: "220px",
          height: "100px",
          marginBottom: "20px",
        }}
      />
      <Typography
        fontSize={"32px"}
        fontWeight={900}
        lineHeight={"38px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textTransform={"uppercase"}
        mb={2}
        textAlign={"center"}
        mt={-7}
      >
        Supercharging <br />
        <span
          style={{
            color: "rgba(169, 60, 255, 1)",
            fontFamily: "Avenir",
          }}
        >
          GPT-4
          <span
            style={{
              fontSize: "24px",
              lineHeight: "28px",
            }}
          >
            o
          </span>
        </span>
      </Typography>
      <Typography
        fontSize={"16px"}
        fontWeight={400}
        lineHeight={"24px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textAlign={"center"}
        sx={{
          opacity: 0.7,
        }}
        mb={4}
      >
        TARS is an AI infrastructure capable of training AI models, monetizing
        data, and providing access to powerful GPU’s. It’s an advanced ecosystem
        of modular AI applications backed by the Solana foundation.
      </Typography>
      <Box
        sx={
          {
            // position: "relative",
          }
        }
      >
        <Image
          src={ChatbotScreenshotsMobilePng}
          alt="chatbot screenshots"
          sx={{
            width: "100%",
          }}
        />{" "}
        {isiPhone ? (
          <Box
            sx={{
              position: "absolute",
              zIndex: -100,
              top: isDown600 ? "60%" : "65%",
              height: "auto",

              width: "300px",

              // put in center
              left: "50%",
              transform: "translateX(-50%) rotate(-105deg)",
            }}
          >
            <Image
              src={RightRingAnimatedPng}
              alt="tars logo animated"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              zIndex: -100,
              top: isDown600 ? "60%" : "65%",
              height: "50vh",

              width: "130vw",

              // put in center
              left: "50%",
              transform: "translateX(-50%) rotate(-105deg)",
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <source src={RightRingAnimation} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SuperchargingSectionOneMobile;
