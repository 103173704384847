import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Image from "../../components/common/Image";
import {
  MultiNodesAnimatedPng,
  MultiNodesAnimation,
  TwoSvg,
} from "../../assets";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isSafari } from "../../utils";

const SuperchargingSectionTwo = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(43, 9, 87, 0) 0%, #9C75DC 100%)",

        padding: "3px",
        width: "90%",
        margin: "0 auto",
        borderRadius: "40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "0px 100px",
          paddingBottom: "20px",
          zIndex: 2,
          position: "relative",
          background: "linear-gradient(180deg, #06030A 0%, #150925 100%)",
          overflow: "hidden",
          borderRadius: "40px",
        }}
      >
        <Image
          src={TwoSvg}
          alt="two svg"
          sx={{
            width: "220px",
            height: "100px",
            marginBottom: "20px",
          }}
        />
        <Typography
          fontSize={"43px"}
          fontWeight={900}
          lineHeight={"51px"}
          fontFamily="Avenir"
          color="rgba(255, 255, 255, 1)"
          textTransform={"uppercase"}
          textAlign={"center"}
          mb={2}
        >
          Faster. Cheaper.{" "}
          <span
            style={{
              color: "rgba(169, 60, 255, 1)",
              fontFamily: "Avenir",
            }}
          >
            Infinitely powerful.
          </span>
        </Typography>
        <Typography
          fontSize={"20px"}
          fontWeight={400}
          lineHeight={"30px"}
          fontFamily="Avenir"
          color="rgba(255, 255, 255, 1)"
          textAlign={"center"}
          sx={{
            opacity: 0.7,
            marginBottom: isSafari() ? "0px" : "-50px",
          }}
        >
          Stake $TAI tokens and supercharge your earnings with TARS GPU’s.
          Mining GPU’s <br /> allows you to train AI models & get access to
          exclusive token rewards.
        </Typography>
        {isSafari() ? (
          <Box>
            <Image
              src={MultiNodesAnimatedPng}
              alt="tars logo animated"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: 700,
              marginBottom: "-50px",
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <source src={MultiNodesAnimation} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            width: 700,
            marginTop: "-50px",
          }}
        >
          {/* <Typography
            fontSize={"20px"}
            fontWeight={500}
            lineHeight={"24px"}
            fontFamily="Avenir"
            color="rgba(169, 60, 255, 1)"
            textAlign={"center"}
            sx={{
              // text gradient
              background: "linear-gradient(90deg, #A93CFF 0%, #7A3CFF 100%)",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            }}
          >
            COMING SOON...
          </Typography> */}
          <StakeNowButton />
        </Box>
      </Box>
    </Box>
  );
};

export default SuperchargingSectionTwo;

const StakeNowButton = () => {
  return (
    <Button
      sx={{
        // background: "linear-gradient(90deg, #303030 0%, #101010 100%);",
        backgroundColor: "rgba(169, 60, 255, 1)",
        height: "45px",
        width: "180px",
        borderRadius: "30px",
        border: "1px solid rgba(156, 117, 219, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // opacity: "57%",
      }}
      onClick={() => {
        window.open("https://stake.tars.pro/", "_blank");
      }}
    >
      <Typography
        fontSize={"17px"}
        fontWeight={500}
        lineHeight={"20px"}
        fontFamily="Avenir"
        color="rgba(255, 255, 255, 1)"
        textTransform={"none"}
      >
        Stake Now
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          height: "18px",
          width: "18px",
          marginLeft: "8px",
          color: "rgba(255, 255, 255, 1)",
        }}
      />
    </Button>
  );
};
